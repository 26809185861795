import { ReactNode } from "react";

import { Logo } from "../icons";

interface Props {
  info: ReactNode;
  children: ReactNode;
}

export default function LeftCol({ info, children }: Props) {
  return (
    <div className="relative flex flex-col justify-end flex-1 px-4 py-12 lg:justify-center sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <Logo className="absolute w-auto h-16 lg:hidden lg:h-12 top-2" />
      <div className="w-full mx-auto lg:w-96">
        {info}
        <div className="w-full mx-auto mt-8 space-y-6 lg:w-96">{children}</div>
      </div>
    </div>
  );
}
