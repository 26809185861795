import PromoSlider from "./PromoSlider";

export default function RightCol() {
  return (
    <div className="relative flex-1 hidden w-0 bg-gray-300 lg:block">
      <div className="absolute flex flex-col justify-end mb-40 inset-10">
        <PromoSlider />
      </div>
    </div>
  );
}
