import { useEffect, useState } from "react";
import { LockClosedIcon, PencilAltIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import { Snackbar } from "@mui/material";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";

import Spinner from "../components/base/feedback/Spinner";
import { Button } from "../components/base/form-controls/Button";
import ColumnInfo from "../components/home/BrandInfo";
import ForgotPassword from "../components/home/ForgotPassword";
import LeftCol from "../components/home/LeftCol";
import RightCol from "../components/home/RightCol";
import { getLayout as getBaseLayout } from "../components/layouts/BaseLayout";
import { ROLE, RoutePath } from "../utils/constants";

interface PWAProps {
  open: boolean;
  vertical: "top" | "bottom";
  horizontal: "left" | "right" | "center";
  deferredPrompt: any;
}
export default function Landing() {
  const { data: session, status } = useSession();
  const loading = status === "loading";
  const { push } = useRouter();

  const [pwaState, setPWAState] = useState<PWAProps>({
    open: false,
    vertical: "top",
    horizontal: "right",
    deferredPrompt: undefined,
  });

  const pwaPromptCallback = (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    setPWAState({ ...pwaState, open: true, deferredPrompt: e });
    return false;
  };

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android|Android/i.test(userAgent)) {
      window.addEventListener("beforeinstallprompt", pwaPromptCallback);

      return () => {
        if (window) {
          window.removeEventListener("beforeinstallprompt", pwaPromptCallback);
        }
      };
    }
    return;
  }, []);

  const handleClose = () => {
    setPWAState({ ...pwaState, open: false });
  };

  const PWANotification = () =>
    pwaState.open ? (
      <Snackbar
        key={pwaState.vertical + pwaState.horizontal}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="w-auto md:hidden mx-4"
        open={true}
      >
        <div className="sm:hidden mt-20 h-1/3 w-full text-center bg-brand-800 bottom-0 rounded-xl text-sm p-1 shadow-lg">
          <XIcon
            aria-hidden="true"
            className="w-5 h-5 text-gray-300 float-right hover:text-white cursor-pointer"
            onClick={handleClose}
          />

          <div className="mt-8 px-4 flex flex-row row-span-2 gap-x-2">
            <div className="w-full ">
              <p className="text-white mb-4">Try our Android App!</p>

              <p className="mb-4 text-white">
                {`A quick and seamless way to
              interact with ProFile!`}
              </p>
            </div>
            <div className="w-full ">
              <Image
                alt="android icon"
                className="relative"
                height={80}
                objectFit="cover"
                src="/icons/android-80.png"
                width={80}
              />
              <Button
                className="relative bg-white w-auto"
                layout="outline"
                block
                onClick={() => {
                  if (pwaState.deferredPrompt) {
                    pwaState.deferredPrompt.prompt();

                    pwaState.deferredPrompt.userChoice.then(function (choiceResult) {
                      if (choiceResult.outcome == "dismissed") {
                        console.log("User cancelled home screen install");
                      } else {
                        setPWAState({
                          ...pwaState,
                          open: false,
                          deferredPrompt: undefined,
                        });
                      }
                    });
                  }
                }}
              >
                Install App
              </Button>
            </div>
          </div>
        </div>
      </Snackbar>
    ) : null;

  if (loading) return <Spinner />;

  if (session && session.accessToken !== null) {
    if (session.role === ROLE.SuperAdmin) push(RoutePath.SUPER_ADMIN);
    else push(RoutePath.COMPANY);
    return (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Spinner />
      </div>
    );
  }

  const LoginButton = () => (
    <Button
      className="relative"
      href={RoutePath.LOGIN}
      tag="a"
      block
      onClick={(e: React.SyntheticEvent) => {
        e.preventDefault();
        push(RoutePath.LOGIN);
      }}
    >
      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
        <LockClosedIcon aria-hidden="true" className="w-5 h-5 text-white" />
      </span>
      Login
    </Button>
  );

  const SignUpButton = () => (
    <Link href={RoutePath.REGISTER} passHref>
      <Button className="relative" layout="outline" block>
        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
          <PencilAltIcon aria-hidden="true" className="w-5 h-5 text-brand-800" />
        </span>
        Admin Registration
      </Button>
    </Link>
  );

  return (
    <div className="flex h-screen min-h-screen">
      <LeftCol info={<ColumnInfo />}>
        <LoginButton />
        <SignUpButton />
        <ForgotPassword />
      </LeftCol>
      <RightCol />
      <PWANotification />
    </div>
  );
}

Landing.getLayout = getBaseLayout;
