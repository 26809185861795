interface Props {
  linkClassName?: string;
}

export default function ForgotPassword({ linkClassName }: Props) {
  return (
    <div className="text-center">
      <a
        className={`relative text-center cursor-pointer text-brand-600 font-medium ${linkClassName}`}
        href="/forgotPassword"
      >
        Forgot Password?
      </a>
    </div>
  );
}

ForgotPassword.defaultProps = {
  linkClassName: "hover:text-brand-700",
};
