import classnames from "classnames";

export interface SpinnerProps {
  fullScreen?: boolean;
}

export default function Spinner(props: SpinnerProps) {
  const topDivStyles = classnames("flex justify-center items-center", {
    "min-h-screen": props.fullScreen,
  });

  return (
    <div className={topDivStyles}>
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-brand-500"></div>
    </div>
  );
}
