import tw from "tailwind-styled-components";

export const Title = tw.h2`
  mt-6
  text-3xl
  lg:font-extrabold
  font-bold
  text-gray-900
  text-center`;

export const Subtitle = tw.p`
  mt-2
  text-base
  text-gray-600
  text-center`;

export const H3 = tw.h3`text-lg leading-6 font-medium text-gray-900`;
