import { FC, SVGProps } from "react";
import Slider from "react-slick";

import { Subtitle, Title } from "../base/typography";
import {
  UndrawAgreement,
  UndrawBooking,
  UndrawDoctors,
  UndrawSecureServer,
} from "../icons";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function PromoSlider() {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dotsClass: "customSlickDots",
    autoplay: true,
  };

  const sliderContent = [
    {
      title: "Book Appointments",
      subtitle: "Schedule and manage appointments for patients",
      drawing: UndrawBooking,
    },
    {
      title: "Sign Forms",
      subtitle:
        "Create or upload forms and documents for patients to review and sign",
      drawing: UndrawAgreement,
    },
    {
      title: "Add Multiple Users",
      subtitle:
        "Add other providers in your company to ProFile and allow them to manage their own patients and appointments",
      drawing: UndrawDoctors,
    },
    {
      title: "HIPAA Compliant",
      subtitle:
        "All electronic protected health information (e-PHI) is secure, encrypted, and available on demand",
      drawing: UndrawSecureServer,
    },
  ];

  const SliderComponent = (
    title: string,
    subtitle: string,
    Drawing: FC<SVGProps<SVGSVGElement>>,
    key: number
  ) => {
    return (
      <div key={key}>
        <div className="flex flex-col space-y-36">
          <Drawing className="h-80 object-center object-contain" />
          <div>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Slider {...sliderSettings}>
      {sliderContent.map((content, i) =>
        SliderComponent(content.title, content.subtitle, content.drawing, i)
      )}
    </Slider>
  );
}
