import { Subtitle, Title } from "../base/typography";
import { Logo } from "../icons";

export default function ColumnInfo() {
  return (
    <div className="flex flex-col mb-2 lg:block lg:mb-0">
      <Logo className="hidden w-auto h-24 lg:block lg:h-12" />
      <Title className="text-left">ProFile Aesthetic Management</Title>
      <Subtitle className="text-left">
        Your HIPPA compliant appointment and form management solution
      </Subtitle>
    </div>
  );
}
